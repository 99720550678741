import React from 'react'

const PrintDataPin = () => {
    const rPins = `1604325031,1619462750,1686015784,1691373682,1660570187,1660867781,
    1687549603,1641056573,1657839635,1648995316,1623150779,1618582039,
    1641396957,1614076815,1656144770,1682019517,1692465912,1617964169,
    1685177480,1650896510,1608923693,1631180620,1608427513,1602716894,
    1633775609,1681543097,1636056978,1698194304,1647035395,1627386845,
    1616710485,1686891400,1607124230,1692354896,1622565181,1635176442,
    1617944750,1616930478,1644507309,1645973412,1668821009,1679228694,
    1604388645,1621805719,1602961308,1654678196,1658162810,1625012970,
    1649803101,1626942373,1637952516,1610062697,1639708759,1617815036,
    1697302701,1660284673,1623073086,1684299815,1667804826,1607629104,
    1651208325,1656295484,1637651492,1656247016,1659329668,1657994301,
    1645962375,1615641984,1672741505,1658477192,1641376853,1697572442,
    1640979730,1697314687,1634317895,1661475130,1632710954,1638627430,
    1628637416,1631467351,1677986015,1650094481,1668811265,1655671473,
    1652941308,1688079206,1617054071,1617689643,1668804325,1627369105,
    1675981642,1663012541,1670618325,1666957351,1687296034,1662517649,
    1660304574,1648543516,1605768961,1673224308, 1618630930,1640895106,1664232519,1668911642,1606337714,1627096706,1608516382,1626049156,1659364259,1618732139,1606108122,1607620592,1678296043,1608098156,1642519683,1634630952,1636516719,1674168502,1679631591,1635884605,1665732342,1676017123,1601146703,1698653231,1639643812,1621747040,1661432085,1632791076,1644891523,1609424183,1673259502,1664674211,1613862506,1664692017,1628621607,1607522407,1616301894,1612733899,1602658139,1666153890,1657156830,1652638477,1686241703,1658611863,1663274917,1667182254,1615383840,1642042856,1640292078,1690258630,1694258603,1658360097,1627134790,1637840917,1655013780,1684756190,1653162844,1687579494,1660096537,1685462593
    `;
        const pins = rPins.split(',');
    console.log(pins);
  return (
    <div className='row'>
        {
            pins.map((pin, i) => (
                <div className='col-3 mb-1 card'
        style={{ 
            // height: '50px',
            // backgroundColor: 'red'
         }}
        >
            <div 
            style={{ 
                maxHeighteight: '50px',
                margin: 0
             }}
            className='my-0 d-flex justify-content-evenly align-items-center'>
                <img className='mt-0' src='logo.png' width={25} height={20} />
                <p style={{ 
                    fontSize: 12,
                    fontWeight: 800
                 }} className='mt-2 text-center'> MTN DATA CARD {i < 100?'500MB': '1GB'} </p> 
                <img src='mtn.png' width={25} height={20} />
            </div>
            <div >
                <p style={{ 
                    fontSize: 18,
                    fontWeight: 700
                 }} className='my-0 text-center'> Pin: {pin} </p>
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Load. Dial: *347*119*pin# </p>
                <p style={{ 
                    fontSize: 10,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Check Balance. Dial: *461*4# </p>
            </div>
            <div
            className=''
             style={{ 
                border: 1,
                borderStyle: 'groove',
                borderBottomColor: 'red',
                color: 'white',
                backgroundColor: 'black',
                maxHeight: '55px'
                }}
            >
            <p 
            style={{ 
                fontSize: 11,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> Powered by UXTECH </p>
             <p 
            style={{ 
                fontSize: 8,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> (UX-ELITE TECHNOLOGIES) </p>
           <span className='my-0 d-flex justify-content-evenly mb-0'
           style={{ 
            borderBottom: 1,
            borderBottomStyle: 'groove',
            borderBottomColor: 'red',
            alignItems: ''
            }}
           >
                <p style={{ 
                    fontSize: 10,
                    fontWeight: 600
                 }}> www.uxtech.ng </p>
                {/* <p 
                style={{ 
                    fontSize: 12,
                    fontWeight: 600
                 }}
                > 08138282448 </p> */}
           </span>
            </div>
        </div>
            ))
        }
    </div>
  )
}

export default PrintDataPin