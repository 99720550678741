import React from 'react'
import {Button, Modal,} from 'react-bootstrap';
import {useState} from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function NewsAlert({user}) {
  const navigate = useNavigate()
    const [show, setShow] = useState(false)
    useEffect(() =>{
        setTimeout(() =>{setShow(true)}, 1000)
    }, [])
  return user && (
    <div>
<Modal
        show={show}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"  >
            
           <h1 >  <img src={require('./image/logo.png')}/> <b > Notification!!! </b></h1>
          </Modal.Title>
          
        </Modal.Header>
        
        <Modal.Body>
            <center>
          <ul>
          {/* {
              user.bvn.length > 0 || user.nin.length > 0 ?
              <>
              <li>
                Welcome to UXTECH. We offer professional IT and premium Telecom services.
              </li>
              <li>
                All Services are active!
              </li>
              <li>
              Message us on Whatsapp so we can add you to our Whatsapp broadcast channel. Our Whatsapp number is 08138282448.
              </li>
              </>
              // <li>
              //   MTN SME is not stable. This is directly from MTN.
              // </li>
              :
              <li>
                Dear Customers, In respect to the CBN New KYC policy we are required to link your BVN or NIN to your Virtual Bank Account Numbers.
                Therefore, we urge you to go to your profile and add either your BVN or NIN or both before the new deadline. Thank you.
                Click and go to your Profile👇🏽<br/>
                <a href='#' onClick={() => navigate('/profile')}>Go to Profile Now! </a>
              </li>
            } */}
            
            <li>
              Welcome to UXTECH. We offer professional IT and premium Telecom services.

              {/* <p> <b> SS </b></p> */}
              <p> <b> MTN SME STOCK is scarce. Purchase MTN GIFTING as alternative. </b></p>
              {/* <p> We will be introducing new MTN plans soon!</p> */}
            </li>
            <li>
                <p><i> Our customers are our first priority. </i></p>
            </li>
            {/* <li>
              <b>We had challenge earlier with our service server. </b>
              <p>All pending orders have been resolved or refunded. We apologise for any inconviniency you may have experienced.</p>
             
              
              <p> <i>  Thank you for your patience. </i></p>
            </li> */}
            {/* <li>
              <b>Message us on Whatsapp so we can add you to our Whatsapp broadcast channel. <br/> 
                Our Whatsapp number is 08138282448. Thank you for choosing us.
              </b>
            </li> */}
            {/* <li>
            MTN data plans network is not stable. Once you try to purchase and it failed, wait a while and try again later.
            </li> */}
            {/* <li>
                 <b> MTN GIFTING is not sending delivery message. Dial *323*4# to check balance after purchase.</b>
            </li> */}
            {/* <li>
                 <b>MTN SME is sending wrong expiration date in the delivery message. Dial *461*4# to check balance.</b>
            </li> */}
            {/* <li>
              <b>Buy Data Card/Pin MTN CG for 210/215 per GB</b>
            </li> */}
            {/* <li>
                GLO CG is now 230/235.
            </li> */}
          
            
            {/* <li>
                AIRTEL CG is now 300/305 per GB Temporarily.
            </li>
           */}
          </ul>
          
          {/* New year image */}
          {/* <img src = './images/advert.jpg' width='100%'/> */}
          <small><b className='text-muted'>
           contact us on whatsapp for support using any of our contact phone No.
           08129309721, 08138282448
          </b></small> <br/>
            </center>

            {/* <img src='update.jpg' /> */}
          
         
        </Modal.Body>
        <Modal.Footer>
        
          {
          
          <Button variant='dark'
          onClick={() => setShow(false)}
          > Close</Button>
         
          
          }
        
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default NewsAlert
