import {  Form, Button, Toast, Spinner } from "react-bootstrap";
import Cookie  from "react-cookies";
import { useState, useContext, useEffect } from "react";
import {useParams, useLocation, Link, useNavigate } from 'react-router-dom'; 
import Alert from './Alert';
import DataContext from "./DataContext";
import MyButton from "./Button";
import {RegisterUser } from './API';
import Swal from "sweetalert2";

export const Register = () => {
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState('');
    const [active, setActive] = useState(false)
    const [regInputs, setRegInputs] = useState('')
    const {getUser, setUser, activeUser, setActiveUser} = useContext(DataContext);
    const navigate = useNavigate()
    const [color, setColor] = useState('black');
    const style = { backgroundColor: 'white', borderRadius: '40px', color };
    const [createAcount, setCreateAccount] = useState(false);



    const handleRegInput = (e) =>{
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setRegInputs( inputs => ({
          [name]: value,    }));
      }
      
      const handleRegister = async (e) => {
        e.preventDefault()
        setActive(true);
        const   password = e.target.password.value;
        const formData = new FormData(document.getElementById('regForm'));

        let data = new Object()
        formData.forEach((v, k) => {
            data[k] = v;
        })
        data['createAccountStatus'] = createAcount;
        //  console.log(data);
        if(data.phone.length !== 11){
            Swal.fire({
                text: "Phone number cannot be less or greater than 11 digits.",
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setMessage(response.message)
            // setColor('red');
            // setAlert(true)
            setTimeout(() => {
                setActive(false)
            }, 1000)
        }else if( data.password === data.confirm){
            // const data = {
            //     first_name: e.target.fname.value,
            //     last_name: e.target.lname.value,
            //     email: e.target.email.value,
            //     phone: e.target.phone.value,
            //     username: e.target.username.value,
            //     password,
            //     bvn: '3322434532',
            //     createAccountStatus: true
            // }
            if(data.createAccountStatus){
                if(data.bvn.length == 0 && data.nin.length == 0){
                    Swal.fire({
                        text: "You must enter Valide BVN or NIN or Both.",
                        icon: 'warning',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    setActive(false);
                }else{
                    try{
                        const response = await RegisterUser(data)
                    // console.log(response); 
                    if(response.success){
                        setColor('black');
                        // setMessage(response.message)
                        setActiveUser(response);
                        setUser('user', response);
                        
                        // setAlert(true)
                        Swal.fire({
                            text: response.message,
                            icon: 'success',
                            timer: 5000,
                            toast: true,
                            showConfirmButton: false,
                            position: 'top-end'
                        });
                        setTimeout(() => {
                            setActive(false)
                            navigate('/dashboard')
                        }, 2000)
                        // console.log(response)
                    } else{
                        Swal.fire({
                            text: response.response.data.message ? response.response.data.message : response.message,
                            icon: 'error',
                            timer: 5000,
                            toast: true,
                            showConfirmButton: false,
                            position: 'top-end'
                        });
                        // setMessage(response.message)
                        // setColor('red');
                        // setAlert(true)
                        setTimeout(() => {
                            setActive(false)
                        }, 1000)
                        // console.log(response)
                    }
                    }finally{
                        setActive(false);
                    }
                }
            }else{
                const response = await RegisterUser(data)
                console.log(response); 
                if(response.success){
                    setColor('black');
                    // setMessage(response.message)
                    setActiveUser(response);
                    setUser('user', response);
                    
                    // setAlert(true)
                    Swal.fire({
                        text: response.message,
                        icon: 'success',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    setTimeout(() => {
                        setActive(false)
                        navigate('/dashboard')
                    }, 2000)
                    // console.log(response)
                } else{
                    Swal.fire({
                        text: response.response.data.message ? response.response.data.message : response.message,
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    // setMessage(response.message)
                    // setColor('red');
                    // setAlert(true)
                    setTimeout(() => {
                        setActive(false)
                    }, 1000)
                    // console.log(response)
                }
            }
            
        } else{
            Swal.fire({
                text: 'Password does not match Confirm Password',
                icon: 'warning',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setMessage("Password does not match Confirm Password")
            // setColor('red');
            // setAlert(true)
            setTimeout(() => {
                setActive(false)
            }, 2000)
        
        }
    }
    useEffect(() => {
        document.title = "UXTECH | Register"
        // console.log("register page")
    }, [])
    return (
        <Form  method='post' onSubmit={handleRegister}
        // className="card"
        id="regForm"
        >
            <Toast onClose={() => setAlert(false)} show={alert} delay={3000} autohide className='w-100'
            
            style={{ borderRadius: '40px', backgroundColor: 'white' }}
            >
                <Toast.Header className='  bg-light text-dark '  >
                    <small className='me-auto'>Message</small>
                </Toast.Header>

                <Toast.Body className= 'text-center '
                style={style}>
                <p><b> {message} </b></p> 
                </Toast.Body>
            </Toast>
            <div className="row justify-content-center mx-2 mt-2 mb-5"
            // style={{ border: 1, borderColor:'black' }}
            >
            <h2 className="text-center mt-2"> Sign Up</h2>
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mt-2">
                <h5 className="text-muted my-1"> Personal Details </h5>
                        <Form.Group className="mb-1" controlId="">
                            <Form.Label >First Name</Form.Label>
                            <Form.Control type="text" 
                            placeholder="First Name"
                            name="first_name"
                            required
                            />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="">
                            <Form.Label>Surname Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" name="last_name"
                            required
                            />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label> Email </Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" 
                            required
                            />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label> Phone </Form.Label>
                            <Form.Control type="number" placeholder="Enter Phone Number" name="phone" 
                            required
                            />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="">
                            <Form.Label> username</Form.Label>
                            <Form.Control type="text" placeholder="username" name="username"
                            required
                            />
                        </Form.Group>

                       
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mt-2">
                <h5 className="text-muted my-1"> Validation/Pass Key </h5>
                <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" name="password"
                            required
                            />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" name='confirm'
                            required
                            />
                        </Form.Group>
                        <Form.Group>
                        <Form.Group
                        className="card p-2"
                        >
                        <b
                            style={{ 
                                fontSize: 12,
                                fontFamily: 'sans-serif'
                             }}
                            > Will You Like to Create Personal Dedicated Accounts Numbers that you will be using for funding your wallet Now? </b>
                        {/* <div className="d-flex justify-content-center align-items-center">
                            <b> No </b>
                            <Form.Check type='switch'   label=''
                                className="ms-2"
                                
                                        onClick={
                                            (e) => {setCreateAccount(!createAcount)}
                                        }
                                        />
                            <b> Yes </b>
                       </div> */}
                       <div className="d-flex justify-content-center align-items-center w-100"
                        style={{ 
                            border: 0,
                            borderTop: 1,
                            borderBottom: 1,
                            borderStyle: 'groove',
                            borderColor: 'black',
                            borderRadius: '50px'
                        }}
                        >
                            <Button 
                            className="w-50" 
                            style={{ borderRadius: '50px', borderColor: 'white' }} 
                            size='lg'
                            onClick={() => {
                                setCreateAccount(!createAcount)
                                
                                }} variant='outline-dark' active={!createAcount}>
                                    No, Later 
                                </Button>

                            <Button 
                            className="w-50"
                            style={{ borderRadius: '50px', borderColor: 'white' }} 
                            size="lg" 
                            onClick={() => {
                            setCreateAccount(!createAcount)
                            
                            }} variant='outline-dark' active={createAcount}>
                                Yes
                            </Button>  
                        </div>
                        </Form.Group>
                        </Form.Group>
                        {
                            !createAcount &&
                            <small style={{ color:"green" }}>
                                <b>Note: </b>
                                You can create dedicated account numbers later from 'Add Fund'
                                Page.
                            </small>
                        }
                    {
                        createAcount &&
                        <>
                            <h5 className="text-muted mb-0 mt-0"> KWC For Bank Account Creation </h5>
                            <small className=" "
                            style={{ 
                                fontSize: 10,
                                fontWeight: 800,
                                color: 'green'
                             }}
                            > Enter Either BVN or NIN. Provide Both For higher transaction limits. </small>

                            <div className="row">
                                <div className=" col-lg-6 col-md-6 col-12 mb-1" controlId="">
                                    <Form.Label> BVN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter BVN" name="bvn"
                                    // required
                                    />
                                </div>

                                <div className="col-lg-6 col-md-6 col-12 mb-1" controlId="formBasicPassword">
                                    <Form.Label>NIN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter NIN" name="nin"
                                    // required
                                    />
                                </div>
                            </div>
                        </>
                    }
                    
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mt-2 mx-2">
                    <div
                    className="mx-2"
                    >
                        <MyButton name="Register " active={active}/>
                    </div>
                </div>
                        
                        <center>
                        
                        
                        </center>
            
            </div>
        </Form>
    )
}